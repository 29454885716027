

let shadowRoot;
class ProgressBar {
    constructor(id, finalTitle) {
        this.id = id;
        this.finalTitle = finalTitle;
        this.percentage = 0;
        this.intervalId = null;
        this.element = shadowRoot.querySelector('#' + id + '-progress');
        this.isComplete = false;
    }
    updateProgressBar() {
        console.log('inside continueProgress function')
        const progressPath = this.element.querySelector('.progress-bar .progress');
        const dashOffset = 100 - this.percentage;
        progressPath.style.strokeDashoffset = dashOffset;
        this.element.querySelector('.progress-percentage').textContent = `${this.percentage}%`;
        if (this.percentage >= 100 && !this.isComplete) {
            this.completeProgress();
        }
    }
    completeProgress() {
        console.log('inside completeProgress function')
        console.log(this.id)
        this.isComplete = true;
        this.element.querySelector('.progress-title').textContent = this.finalTitle;
        this.element.querySelector('.progress-percentage').style.display = 'none';
        this.element.querySelector('.check-icon').style.display = 'block';
        clearInterval(this.intervalId);
        const event = new Event('progressComplete');
        this.element.dispatchEvent(event);
        if (this.id === 'third') {
            const targetButton = shadowRoot.querySelector('#button-1fdfe248');
            if (targetButton) {
                targetButton.style.visibility = 'visible';
                targetButton.style.transition = 'opacity 0.5s ease-in-out';
                // Small delay to ensure the transition takes effect
                setTimeout(() => {
                    targetButton.style.opacity = '1';
                }, 10);
            }
        } else if (this.id == 'first') {
            window.continueProgress('second');
        } else if (this.id == 'second') {
            window.continueProgress('third');
        }
    }
    showPopup() {
        console.log('inside showPopup function')
        const popupElement = shadowRoot.querySelector('#' + this.id + 'Popup');
        const overlayElement = shadowRoot.querySelector('#' + this.id + 'PopupOverlay');
        popupElement.style.display = 'block';
        overlayElement.style.display = 'block';
        // Trigger reflow to ensure transition works
        popupElement.offsetHeight;
        popupElement.classList.add('visible');
        overlayElement.classList.add('visible');
    }
    hidePopup() {
        console.log('inside hidePopup function')
        const popupElement = shadowRoot.querySelector('#' + this.id + 'Popup');
        const overlayElement = shadowRoot.querySelector('#' + this.id + 'PopupOverlay');
        popupElement.classList.remove('visible');
        overlayElement.classList.remove('visible');
        // Wait for transition to complete before hiding
        setTimeout(() => {
            popupElement.style.display = 'none';
            overlayElement.style.display = 'none';
        }, 300);
    }
    activate() {
        console.log('inside activate function')
        this.element.classList.add('visible');
    }
    startProgress() {
        console.log('inside startprogress function')
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.intervalId = setInterval(() => {
            if (this.percentage >= 100) {
                clearInterval(this.intervalId);
                this.completeProgress();
                return;
            }
            if (this.percentage === 49) {
                clearInterval(this.intervalId);
                this.showPopup();
                return;
            }
            this.percentage++;
            this.updateProgressBar();
        }, 75);
    }
    continueProgress() {
        console.log('inside continueProgress function')
        this.hidePopup();
        this.percentage++;
        this.startProgress();
    }
}

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = (document.querySelector(`[flow-id="${event.detail.flowID}"]`) && document.querySelector(`[flow-id="${event.detail.flowID}"]`).shadowRoot) || document;
    //shadowRoot.querySelector('#start-month').textContent = "December";
    console.log('the page has been loaded')

    function getMonthName(date) {
        return date.toLocaleString('default', {
            month: 'long'
        });
    }

    function updateLabels() {
        const now = new Date();
        const futureDate = new Date();
        futureDate.setMonth(now.getMonth() + 3);
        shadowRoot.querySelector('#start-month').textContent = getMonthName(now);
        shadowRoot.querySelector('#end-month').textContent = getMonthName(futureDate);
    }
    updateLabels();
    shadowRoot.firstProgress = new ProgressBar('first', 'Goals');
    shadowRoot.secondProgress = new ProgressBar('second', 'Custom skin profile matched');
    shadowRoot.thirdProgress = new ProgressBar('third', 'Esdsential skincare selected');
});
window.addEventListener('heyflow-screen-view', (event) => {
    //console.log('heyflow screen view:', event.detail);
});
window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
window.addEventListener('heyflow-screen-view', (event) => {
    // Hide button when entering the progress bar screen
    if (event.detail.screenID === 'id-b4c03f35') {
        const targetButton = shadowRoot.querySelector('#button-3a0e124a');
        if (targetButton) {
            targetButton.style.opacity = '0';
            targetButton.style.visibility = 'hidden';
            // Using visibility instead of display to maintain layout
        }
    }
});


// Listen for the heyflow screen view event
window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenID === 'id-b4c03f35') {
        //console.log('inside window event')
        // Start first progress bar when entering the specified screen
        setTimeout(() => {
            console.log('inside first progress bar function')
            shadowRoot.firstProgress.activate();
            shadowRoot.firstProgress.startProgress();
        }, 100);
    }
});
// Define continueProgress function in global scope
window.continueProgress = function (which) {
    //console.log('inside continueProgress window event')
    if (which === 'first') {
        shadowRoot.firstProgress.continueProgress();
        shadowRoot.firstProgress.element.addEventListener('progressComplete', () => {
            setTimeout(() => {
                shadowRoot.secondProgress.activate();
                shadowRoot.secondProgress.startProgress();
            }, 1000);
        }, {
            once: true
        });
    } else if (which === 'second') {
        shadowRoot.secondProgress.continueProgress();
        shadowRoot.secondProgress.element.addEventListener('progressComplete', () => {
            setTimeout(() => {
                shadowRoot.thirdProgress.activate();
                shadowRoot.thirdProgress.startProgress();
            }, 1000);
        }, {
            once: true
        });
    } else {
        shadowRoot.thirdProgress.continueProgress();
    }
}
window.addEventListener('heyflow-screen-view', (event) => {
    // Hide button when entering the progress bar screen
    if (event.detail.screenID === 'id-b4c03f35') {
        const targetButton = shadowRoot.querySelector('#button-1fdfe248');
        if (targetButton) {
            targetButton.style.opacity = '0';
            targetButton.style.visibility = 'hidden';
            // Using visibility instead of display to maintain layout
        }
    }
});

